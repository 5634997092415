// Unconflict with the stickyHeader.js
body:not(.page-no-banner, .page-has-video-banner) {
  margin-top: 0 !important;
}

body, html { overflow-x: hidden !important; }

// Header Sticky
@media (min-width: 1025px) {
  .pageHeader.sticky .mainCallToAction { display: none; }
  .pageHeader.sticky .headerContent, .pageHeader.sticky .headerContent > *:not(.mainLogo) { margin: 0; }
  .pageHeader.sticky .headerContent a.mainLogo {
    width: 0;
    height: 0;
  }
}

@media (max-width: 1024px) {
  body.pf_noHeaderMedia .pageHeader.sticky  a.mainLogo {
    background-image: url($assets-path + $no-banner-logo);
  }
}

// // Header on smaller screens
// @media (max-width: 640px) {
// .pageHeader:not(.sticky)  .headerContent {
//     padding: 10px 20px 40px 20px !important;
//   }
// }

// Carousel - Read More on mobile view
@media (max-width: 1024px) {
  span.readMore.carouselSlideReadMore {
    background-color: $white;
    color: $_teal;
  }
}
// Carousel - Minimum height on desktop
@media (min-width: 1025px) {
  .carouselSlideTitle img {
    min-height: 580px;
    object-fit: cover;
  }
}

// Carousel with video
body.page-has-video-banner ul.carouselSlides {
  background-color: $grey-lightest;
}

// Home intro - text
.homeIntro p {
  max-width: 900px;
  margin: -3rem auto 0;
}

// Impact stats - icons size
.homeImpactWrapper td img {
  max-height: 100px;
  margin-bottom: .5rem;
}

// Home - Quick giving panel
.homepage-quickgiving--container {
  flex-direction: column;
  .homepage-quickgiving--copy {
    margin: 0 0 20px;
  }
}

// Footer - Box shadow and hover
.footerBox {
  box-shadow: 0 4px 0 rgba($white, 0.65);
  padding: 10px;
  width: 90%;
  margin: 1rem auto;
  transition: 300ms all ease;
  &:hover {
    box-shadow: 0 4px 0 $white;
  }
}

// Card hover state + mobile view
body.homepage,
body:not([class*="sbs"]) {

    .feedItem .feedItemDetailsWrapper,
    .homeFeature .homeFeatureDetailsWrapper,
    .listedPost .listedPostText,
    .listedProduct .listedPostText {
      min-height: 62px;
    }

    // https://www.bugherd.com/projects/177940/tasks/36
    // JK - Goodness there's a lot of bespoke work here!...
    // When there's a row of listed posts and none of them have an image
    // we get this weird bug where the listed post card has no height, so the absolutely positioned
    // details block can't render itself. We need to escape that absolute behaviour if
    // no banner image is present

    .listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem) {
      [class^=listed][class$=Text] {
        position: relative;
        min-height: 100%;
      }
    }

    .listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem) {
      .listed-post-image-link + [class^=listed][class$=Text] {
        position: absolute;
        min-height: 62px;
      }
    }

    .homeFeature:hover, .feedItem:hover, .listedPost:hover, .listedProduct:hover {
      transition: 300ms all ease !important;
      .feedItemDetailsWrapper,
      .homeFeatureDetailsWrapper,
      .listedPostText {
        min-height: 100% !important;
        h2, h3 { margin-top: 1em; }
      }
    }
    // Event card - hover effect
    .listedEvent:hover .listedPostText h2 { margin-top: 2em !important; }
    .homeFeed[class*='event'] .feedItem:hover {
      .feedTitle { margin-top: 2em; }
    }

  // Card - always show summary on mobile
  @media (max-width: 576px) {
    .feedItemDetailsWrapper > p,
    .homeFeatureDetailsWrapper > p,
    .listedPostText > p {
      opacity: 1 !important;
      max-height: none !important;
    }
    .feedItemDetailsWrapper > h3,
    .homeFeatureDetailsWrapper > h2,
    .listedPostText > h2 {
      margin-bottom: .5rem !important;
    }
    .homeFeature, .feedItem, .listedItem {
      margin-bottom: 1.5rem !important;
    }
  }

  // Events - floating date padding
  .listedPost.listedEvent .associatedStartDate,
  .homeFeedevents .feedItem .associatedStartDate {
    padding: .5em 1em;
  }
}

// Shop - hide breadcrumbs
.shopLandingPage nav.breadcrumbWrapper {
  display: none;
}

// Donate form - amount colours
.formQuestion.donationAmount:not(.ccAmount) .donationAmountFigure {
  color: $_purple;
}

// homeIntroBoxes + footerIntroBoxes
.homeIntroWrapper {
  padding-top: 0;
}

.footerBox4 {
  width: 100%;
  position: relative;
  margin: 0 auto;
  padding: 3rem 15px;
  background-color: $grey-lightest;
  box-shadow: none;
  text-align: center;
  &:hover { box-shadow: none; }
  h3 {
    font-size: 2em;
    color: $body-colour;
  }
}

.footerIntroBoxes,
.homeIntroBoxes {
  position: relative;
  width: 100%;
  max-width: 900px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  &:hover {
    a.homeIntroBox,
    a.footerIntroBox {
      background-color: $grey;
    }
  }
}

.homeIntroBoxes {
  margin: 0 auto;
  transform: translateY(-50%);
  @media (max-width: 1024px) {
    margin: 1rem auto 2rem;
  }
}
.footerIntroBoxes {
  margin: 2rem auto 0;
}
// Boxes settigns
a.homeIntroBox {
  width: 25%;
}
a.footerIntroBox {
  width: 33.3%;
}

a.homeIntroBox,
a.footerIntroBox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 0.5rem 1rem;
  font-weight: $font-weight-bold;
  min-height: 185px;
  font-size: 1.65rem;
  border-left: 0.25px solid $grey-lightest;
  border-right: 0.25px solid $grey-lightest;
  text-decoration: none !important;
  line-height: 1.15;
  z-index: 1;
  color: $white !important;
  &:before {
    content: '';
    position: relative;
    display: block;
    margin-bottom: 5px;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 65px;
    height: 65px;
    @media (max-width: 768px) {
      width: 45px;
      height: 45px;
    }
  }
  &:first-of-type {
    border-left: none;
  }
  &:last-of-type {
    border-right: none;
  }

  &.introDiagnose {
    background-color: $_teal;
    &:hover {
      background-color: $_teal !important;
    }
  }

  &.introMembership, &.footerVolunteer {
    background-color: $_green;
    &:hover {
      background-color: $_green !important;
    }
  }

  &.introStories, &.footerDonate {
    background-color: $_purple;
    &:hover {
      background-color: $_purple !important;
    }
  }

  &.introShop, &.footerFundraise  {
    background-color: darken($_light-blue, 10%);
    &:hover {
      background-color: darken($_light-blue, 10%) !important;
    }
  }
}

@media (max-width: 1024px) {
  .homeIntroBoxes,
  .footerIntroBoxes {
    flex-direction: column;
    margin: 1rem auto;
    transform: none;
    a.homeIntroBox,
    a.footerIntroBox {
      width: 100%;
      margin: .25rem 0;
      padding: 1rem;
      min-height: 100px;
    }
  }
  .homeIntro p {
    margin-top: 2rem;
  }
}

// Hide footerBox4 on particular pages
.appealsPostBody .footerBox4,
body[class*='fundraise'] .footerBox4,
body[class*='volunteer'] .footerBox4,
body[class*='donate'] .footerBox4 {
  display: none;
}

// Blockquote
blockquote {
  position: relative;
  &:before, &:after {
    content: "";
    position: absolute;
    display: block;
    width: 1000px;
    height: 100%;
    top: 0;
    background-color: $blockquote-background-colour;
  }
  &:before { right: 100%; }
  &:after { left: 100%; }
}


// Header - no banner
body.page-no-banner .pageHeader {
  box-shadow: 0 0 7.5px rgba($black, 0.35);
}

// Header - no banner - social icons hover
body.page-no-banner .headerContent ul.socialIcons a:hover:before {
  color: darken($_light-blue, 10%);
}

// Header absolute - search bar input
body:not(.page-no-banner) #siteSearch:focus {
  color: $white;
}

// Nav search + social icons - on mobile
@media (max-width: 1024px) {
  #siteSearch:focus, #siteSearch::placeholder,
  .menuMain ul.socialIcons a:before {
    color: $_teal !important;
  }
}

// Mixed modules listing pages
body.mixedModuleList {
  .contentBlockWrapper .listContent div[class*='mixedPosts'] {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  // Kill sidebars
	aside.postAside {
		display: none;
	}
  // Order the posts by type
  .mixedPostsSeniorLeadershipTeam { order: 1; }
  .mixedPostsStaff { order: 2; }
  .mixedPostsChairOfTheBoard { order: 3; }
  .mixedPostsTrustees { order: 4; }
  .mixedPostsPatronsAmbassadors { order: 5; }
  .mixedPostsMedicalAdvisors { order: 6; }
  .mixedPostsVolunteers { order: 7; }
  .mixedPostsJoinTheTeam { order: 8; }

  .mixedPostsCurrentResearch { order: 1; }
  .mixedPostsCompletedResearch { order: 2; }
  .mixedPostsVolunteerResearch { order: 3; }

  .mixedPostsUpcomingEvents { order: -1; }
  .mixedPostsPastEvents { order: 1; }

  .mixedPostsDystoniaMattersWebinars { order: 1}
  .mixedPostsDystoniaMattersPodcast { order: 2}
  .mixedPostsDystoniaAnimated { order: 3}
  .mixedPostsTheBookletBank { order: 4}

  .listFooter { order: 10; }

  // Section titles
  .mixedPostsStaff, .mixedPostsTrustees, .mixedPostsPatronsAmbassadors, .mixedPostsMedicalAdvisors, .mixedPostsVolunteers, .mixedPostsJoinTheTeam, .mixedPostsCurrentResearch, .mixedPostsCompletedResearch, .mixedPostsVolunteerResearch, .mixedPostsPastEvents, .mixedPostsUpcomingEvents, .mixedPostsInformationAndSupport, .mixedPostsAdmin, .mixedPostsGettingInvolved, .mixedPostsDystoniaUK, .mixedPostsDystoniaMattersWebinars, .mixedPostsDystoniaMattersPodcast, .mixedPostsDystoniaAnimated, .mixedPostsTheBookletBank, .mixedPostsSeniorLeadershipTeam, .mixedPostsChairOfTheBoard {
    &:before {
      display: block;
      width: calc(100% - #{$site-bleed * 2});
      margin: $gap-width*1.5 $site-bleed $gap-width*0.75;
      padding: 0;
      color: $body-colour;
      font-family: $headings-font-family;
      font-weight: $headings-font-weight;
      font-size: $font-size-h3;
      border-bottom: 5px solid $grey-lightest;
    }
  }

  .mixedPostsSeniorLeadershipTeam:before { content: "Senior Leadership Team"; }
  .mixedPostsStaff:before { content: "Staff"; }
  .mixedPostsChairOfTheBoard:before { content: "Chair of the Board"; }
  .mixedPostsTrustees:before { content: "Trustees"; }
  .mixedPostsPatronsAmbassadors:before { content: "Patrons & Ambassadors"; }
  .mixedPostsMedicalAdvisors:before { content: "Medical Advisors"; }
  .mixedPostsVolunteers:before { content: "Volunteers"; }
  .mixedPostsJoinTheTeam:before { content: "Join the Team"; }

  .mixedPostsCurrentResearch:before { content: "Current Research"; }
  .mixedPostsCompletedResearch:before { content: "Completed Research"; }
  .mixedPostsVolunteerResearch:before { content: "Getting involved in research"; }

  .mixedPostsPastEvents:before { content: "Past events"; }
  .mixedPostsUpcomingEvents:before { content: "Upcoming events"; }

  .mixedPostsInformationAndSupport:before { content: "Information and support"; }
  .mixedPostsDystoniaUK:before { content: "Dystonia UK"; }
  .mixedPostsGettingInvolved:before { content: "Getting Involved"; }

  .mixedPostsDystoniaMattersWebinars:before { content: "Reach Out, Reach All Webinars"}
  .mixedPostsDystoniaMattersPodcast:before { content: "Dystonia Matters Podcast"}
  .mixedPostsDystoniaAnimated:before { content: "Dystonia Animated"}
  .mixedPostsTheBookletBank:before { content: "The Booklet Bank"}
}



// Mailchimp setup
.footerBox[class*='ewsletter'] #mc_embed_signup_scroll {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  // h2 { font-size: $font-size-h4; }

  .indicates-required {
    font-size: .75em;
    color: rgba($white, 0.65);
    margin-left: auto;
  }

  > .mc-field-group {
    display: flex;
    flex-direction: column;
    margin: 0 0 10px 0;

    &.eMail,
    &.lName,
    &.fName { 
      width: 100%;
    }

    > label {
      margin: 0;
      color: rgba($white, 0.65);
      font-size: $font-size-small;
    }

    @media (max-width: map-get($breakpoints, sm)) {
      width: 100%;
      margin: 0 0 10px 0;
    }
  }

  >.mc-field-group.gdpr-mergeRow {
    font-size: $font-size-small;
    p { margin-bottom: 0.25em; }
  }

  fieldset.gdprRequired {
    display: flex;
    .av-checkbox {
      margin-right: 10px;
      margin-left: 10px;
    }
  }

  input.cta-button {
    margin-top: 10px;
    background-color: $_green;

    &:hover {
      background-color: darken($_green, 10%);
    }
  }
}


// Listed Post - Embed video
.listedPost[class*="sbs"] .embed-container {
  position: relative !important;
  height: 0 !important;
  padding-bottom: 56.25% !important;
  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

// Product price fix
.listedProductText .productPrice {
  color: $white;
}

.pf_noHeaderMedia #menuAdmin a {
  color: $link-colour;
}

// DES-5367
table.non-full-width {
  width: initial !important;
}