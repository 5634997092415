// Config values
$platform-name: 'dystoniaredesign';
$assets-path: "../assets/";

// Colours
$_teal: #006072;
$_green: #74b226;
$_purple: #764897;
$_light-blue: #64c2c8;

$brand-primary: $_teal;
$brand-secondary: $_green;
$donate-colour: $_purple;

// Greyscale
$black: #000;
$grey-dark: #222;
$grey: #575757;
$grey-light: #B1B2B4;
$grey-lighter: #E1E2E3;
$grey-lightest: #F0F0F0;
$white: #FFF;

// Layout
$container-max-width: 100%;
$gap-width: 30px;
$site-bleed: 20px;

$border-radius: 0;
$border-colour: $grey-lighter;
$box-shadow: none;

// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1200px
);

// Spacers
$spacer: 1rem;
$spacer-y: $spacer;
$spacer-x: $spacer;

// Misc
$body-bg: $white;
$body-colour: $grey-dark;
$profile-pics-enabled: false;
$border-width: 1px;
$date-ordinals-enabled: true;

// Transitions
$transition-duration: 0.1s;
$transition-duration-slow: 0.5s;
$transition-curve: ease;

// Logo
$logo: "Dystonia-logo-white-green-i.png";
$logo-retina: $logo;
$logo-width: 195px;
$logo-height: 80px;
$logo-absolute: false;
$logo-breakpoint: map-get($breakpoints, md);
$logo-mobile: $logo;
$logo-mobile-retina: $logo-mobile;
$logo-mobile-width: $logo-width * 0.65;
$logo-mobile-height: $logo-height * 0.65;

// Typography
//

$font-family-base: 'Muli', sans-serif;
$font-size-base: 1rem;
$font-size-large: 1.25em;
$font-size-small: 0.875em;

$font-weight-normal: 400;
$font-weight-bold: 900;
$font-weight-base: $font-weight-normal;
$line-height-base: 1.5;

$font-size-h1: 2.5em;
$font-size-h2: 2em;
$font-size-h3: 1.75em;
$font-size-h4: 1.5em;
$font-size-h5: 1.25em;
$font-size-h6: 1em;

$headings-margin-top: 0.5em;
$headings-margin-bottom: 0.35em;
$headings-font-family: inherit;
$headings-font-weight: $font-weight-bold;
$headings-line-height: 1.1;
$headings-colour: inherit;
$headings-text-transform: none;
$headings-letter-spacing: initial;

$hr-border-colour: $border-colour;
$hr-border-width: $border-width;

// Links
$link-colour: $_teal;
$link-decoration: none;
$link-hover-decoration: underline;
$link-hover-style: darken; // darken or lighten text links on hover

// Icon font
$icon-font-family: "FontAwesome";

// Buttons
$btn-background-colour: $brand-primary;
$btn-text-colour: text-contrast($btn-background-colour);
$btn-border-radius: 2px;
$btn-border-width: 0;
$btn-padding-x: 1.45em;
$btn-padding-y: 0.725em;
$btn-font-family: $font-family-base;
$btn-font-weight: 700;
$btn-text-transform: none;
$btn-line-height: 1.25;
$btn-hover-style: darken;
$btn-transition-duration: $transition-duration;

// Donate button
$donate-btn-background-colour: $_green;
$donate-btn-colour: $white;

// Social icons
$social-icons-background-colour: transparent;
$social-icons-colour: $_teal;
$social-icons-hover-colour: $_light-blue;
$social-icons-width: 40px;
$social-icons-height: 40px;
$social-icons-font-size: 1.4rem;
$social-icons-gap: 5px;
$social-icons-border-radius: 2px;

// Header - Social icons
$social-icons-header-background-colour: transparent;
// $social-icons-header-colour: $_light-blue;
// $social-icons-header-hover-colour: darken($_light-blue, 10%);
$social-icons-header-colour: $white;
$social-icons-header-hover-colour: $_light-blue;

// Footer - Social icons
$social-icons-footer-background-colour: transparent;
$social-icons-footer-colour: $white;
$social-icons-footer-hover-colour: $_light-blue;

// Share this page
$share-enabled: true;
$share-text-align: center;
$share-label-font-family: $font-family-base;
$share-label-font-size: $font-size-h4;
$share-label-font-colour: $body-colour;

// Form elements
$input-padding-y: 0.375rem;
$input-padding-x: 0.75rem;
$input-line-height: 1.25;
$input-colour: $body-colour;
$input-border-colour: #ced4da;
$input-border: 1px solid $input-border-colour;
$input-border-radius: 0;
$input-placeholder-colour: $grey-light;

// Field groups
$field-group-label-width: 280px;

// Tables
$table-cell-padding: 0.75rem;


// Card
$card-gap-width: $gap-width;
$card-border-radius: 0;
$card-border: 0px solid transparent;
// $card-box-shadow: 0 4px 0 $_teal;
$card-box-shadow: none;
$card-text-align: left;
$card-image-on: true;
$card-image-opacity: 1;
$card-image-background-colour: $brand-primary;
$card-image-padding: 0;
$card-image-border-radius: 0;
$card-details-padding: $spacer $spacer * 1.5;
// $card-details-background-colour: rgba($white, 0.85);
$card-details-background-colour: rgba($_teal, 0.8);
$card-heading-font-family: $headings-font-family;
$card-heading-text-transform: $headings-text-transform;
// $card-heading-colour: $_teal;
$card-heading-colour: $white;
$card-heading-font-size: $font-size-h5;
$card-heading-margin-bottom: $spacer * 0.5;
$card-summary-enabled: true;
// $card-summary-colour: $body-colour;
$card-summary-colour: $white;
$card-summary-font-size: $font-size-base;
$card-footer-on: false;
$card-footer-background-colour: rgba($black, 0.05);
$card-footer-colour: $body-colour;
$card-min-width: 280px;

// Card basic
$card-basic-image-position: above; // above, below

// Card side by side
$card-side-by-side-image-position: left;
$card-side-by-side-image-width: 50%;
$card-side-by-side-vertical-text-position: center;
$card-side-by-side-breakpoint: map-get($breakpoints, sm);


// Card text overlay
$card-text-overlay-show-summary-on-hover: true;
$card-text-overlay-summary-always-on: false;
$card-text-overlay-details-max-width: 100%;
$card-text-overlay-details-position-y: bottom;
$card-text-overlay-details-position-x: left;

// Card hover state
$card-hover-border-colour: transparent;
// $card-hover-box-shadow: 0 4px 0 $_teal;
$card-hover-box-shadow: none;
$card-hover-image-opacity: 1;
$card-hover-image-scale: 1.0125;
// $card-hover-details-background-colour: rgba($white, 0.85);
$card-hover-details-background-colour: rgba($_teal, 0.9);
// $card-hover-heading-colour: $card-heading-colour;
$card-hover-heading-colour: $white;
// $card-hover-summary-colour: $body-colour;
$card-hover-summary-colour: $white;

// Event cards
$event-card-floating-date: true;
$event-card-floating-date-background-colour: rgba($_teal, 0.8);
$event-card-floating-date-colour: $white;
$event-card-floating-date-border-radius: 0;
$event-card-floating-date-margin-y: $spacer*0.5;
$event-card-floating-date-margin-x: $spacer;

// Menu admin
$menu-admin-enabled: true;
$menu-admin-max-width: 100%;
$menu-admin-background-colour: transparent;
$menu-admin-contents-max-width: $container-max-width;
$menu-admin-font-size: $font-size-small;
$menu-admin-align-items: right;
$menu-admin-link-colour: $white;
$menu-admin-absolute: true;
$menu-admin-login-link-enabled: true;
$menu-admin-manager-link-enabled: false;
$menu-admin-my-details-link-enabled: false;
$menu-admin-logout-link-enabled: true;

// Header
$header-background-colour: $white;
$header-box-shadow: none;
$header-absolute: true;
$header-absolute-background: rgba($black, 0.6);
$header-absolute-breakpoint: 1px;

// Header content
$header-content-max-width: $container-max-width;
$header-content-margin-top: $spacer;
$header-content-margin-bottom: $spacer;
$header-content-padding-top: 0;
$header-content-padding-bottom: 0;
$header-content-padding-left: $site-bleed;
$header-content-padding-right: $site-bleed;

// Tagline
$tagline-enabled: false;

// Main call to action
$cta-btn-background-colour: $_green;
$cta-btn-colour: $btn-text-colour;
$cta-btn-gap: 5px;
// $cta-padding-right-at-nav-breakpoint: 6.5em;
$cta-btn-breakpoint: map-get($breakpoints, sm);
$cta-donate-on-appeal: false;

// Search
$header-search-enabled: true;
$header-search-margin-right: 5px;
$header-search-breakpoint: map-get($breakpoints, lg);

// Search - input
$header-search-input-max-width: 180px;
$header-search-input-margin-right: 0px;
$header-search-input-padding: $input-padding-y $input-padding-x;
$header-search-input-placeholder-colour: $input-placeholder-colour;
$header-search-input-border-radius: 0;
// $header-search-input-border-colour: $_light-blue;
$header-search-input-border-colour: $white;
$header-search-input-border-width:  0 0 2px 0;
$header-search-input-background-colour: transparent;
$header-search-input-colour: $white;
// $header-search-input-focus-background-colour: rgba($black, 0.08);
$header-search-input-focus-background-colour: rgba($white, 0.08);
$header-search-input-focus-colour: $body-colour;

// Search - button
$header-search-button-width: 40px;
$header-search-button-height: 40px;
$header-search-button-background-colour: transparent;
$header-search-button-icon-font-size: 1.4rem;
// $header-search-button-icon-colour: $_light-blue;
$header-search-button-icon-colour: $white;
$header-search-button-border-radius: 0;

// Search when in nav
$nav-search-enabled: true;
$nav-search-input-padding: $input-padding-y $input-padding-x;
$nav-search-input-placeholder-colour: $_teal;
$nav-search-input-border-colour: $_teal;
$nav-search-input-border-width: 0 0 1.5px 0;
$nav-search-input-background-colour: transparent;
$nav-search-button-background-colour: transparent;
$nav-search-button-icon-colour: $_teal;

// Social icons
$header-social-icons-margin-right: 20px;
$header-social-icons-margin-bottom: 0;
$header-social-icons-above-cta-buttons: false;
$header-social-icons-breakpoint: map-get($breakpoints, lg);

// Sticky header
$header-sticky-enabled: true;
$header-sticky-background-colour: $header-background-colour;
$header-sticky-box-shadow: 0 0 7.5px rgba($black, 0.35);
$header-sticky-logo-width: $logo-width*0.5;
$header-sticky-logo-height: $logo-height*0.5;
$header-sticky-menu-admin-enabled: false;
$header-sticky-search-enabled: false;
$header-sticky-social-icons-enabled: false;
$header-sticky-cta-btns-enabled: true;
$header-sticky-header-content-margin-top: 10px;
$header-sticky-header-content-margin-bottom: 10px;
$header-sticky-nav-margin-bottom: 0;

// Navigation
$nav-type: normal;
$nav-font-family: $font-family-base;
$nav-breakpoint: map-get($breakpoints, lg);
$nav-background-colour: $_teal;
$nav-text-transform: none;

// Navigation - Top level items
$nav-top-level-item-padding: 15px 22.5px;
$nav-top-level-item-colour: $white;
$nav-top-level-item-font-family: $nav-font-family;
$nav-top-level-item-font-size: $font-size-base;
$nav-top-level-item-font-weight: 700;
$nav-top-level-item-background-colour: transparent;
$nav-top-level-item-hover-colour: $nav-top-level-item-colour;
$nav-top-level-item-hover-background-colour: rgba($black, 0.15);
$nav-top-level-chevrons-enabled: false;
$nav-top-level-chevrons-colour: $nav-top-level-item-colour;
$nav-top-level-first-item-home-icon: false;
$nav-top-level-first-item-home-icon-font-size: 20px;

// Navigation - Submenus
$nav-submenu-width: 220px;
$nav-submenu-background-colour: $grey-lightest;
$nav-submenu-box-shadow: 0 4px 0 $_teal;
$nav-submenu-border-radius: 0;
$nav-submenu-item-padding: 10px 20px;
$nav-submenu-item-colour: $_teal;
$nav-submenu-item-font-family: $nav-font-family;
$nav-submenu-item-font-size: $font-size-base;
$nav-submenu-item-font-weight: 700;
$nav-submenu-item-hover-colour: $nav-submenu-item-colour;
$nav-submenu-item-hover-background-colour: rgba($black, 0.05);
$nav-submenu-chevrons-enabled: true;
$nav-submenu-chevrons-colour: $_teal;

// Navigation - Burger button
$burger-btn-background-colour: $_teal;
$burger-btn-text-colour: $btn-text-colour;
$burger-btn-icon-breakpoint: null;
// $burger-btn-bar-breakpoint: 640px;
$burger-btn-bar-breakpoint: null;

// Navigation - Nav normal
$nav-normal-max-width: 100%;
$nav-normal-margin-top: 0;
$nav-normal-margin-bottom: 0;
$nav-normal-contents-max-width: $container-max-width;
$nav-normal-align-items: center;
$nav-normal-border-radius: 0;
$nav-normal-mobile-background-colour: $grey-lightest;
$nav-normal-mobile-top-level-item-colour: $_teal;
$nav-normal-mobile-submenu-background-colour: rgba($black, 0.08);
$nav-normal-mobile-submenu-item-colour: $_teal;
$nav-normal-mobile-chevrons-enabled: true;
$nav-normal-mobile-chevrons-colour: $_teal;

// Header - No banner
$no-banner-header-background-colour: $white;
$no-banner-logo: "Dystonia-logo-teal-green-i.png";
$no-banner-social-icons-header-background-colour: transparent;
$no-banner-social-icons-header-colour: $_teal;
$no-banner-social-icons-header-hover-colour: $_teal;
$no-banner-nav-background-colour: $_teal;
$no-banner-nav-top-level-item-colour: $white;
$no-banner-header-search-input-placeholder-colour: $body-colour;
$no-banner-header-search-input-border-colour: $_teal;
$no-banner-header-search-input-background-colour: transparent;
$no-banner-header-search-button-background-colour: transparent;
$no-banner-header-search-button-icon-colour: $_teal;

// Carousel
$carousel-max-width: 100%;
$carousel-border-radius: 0;
$carousel-margin-top: 0;
$carousel-margin-bottom: 0;
$carousel-image-overlay: rgba($black, .4);
$carousel-contents-max-width: $container-max-width;
$carousel-breakpoint: map-get($breakpoints, lg);
$carousel-details-style: overlay;
$carousel-details-position-y: bottom;
$carousel-details-position-x: center;
$carousel-details-background-colour: transparent;
$carousel-details-box-shadow: 0;
$carousel-details-max-width: 640px;
$carousel-details-min-height: auto;
$carousel-details-padding: $spacer * 4;
$carousel-details-margin-y: 8rem;
$carousel-details-margin-x: 0;
$carousel-details-border-radius: 0;
$carousel-details-text-align: center;
$carousel-heading-text-transform: $headings-text-transform;
$carousel-heading-colour: $white;
$carousel-heading-font-size: $font-size-h2;
$carousel-summary-enabled: true;
$carousel-summary-colour: $white;
$carousel-summary-font-size: inherit;
$carousel-read-more-enabled: false;
$carousel-read-more-background-colour: $btn-background-colour;
$carousel-read-more-border-colour: none;
$carousel-read-more-colour: $btn-text-colour;
$carousel-read-more-font-size: $font-size-small;

// Carousel - Controls
$carousel-controls-enabled: true;
$carousel-controls-button-size: 40px;
$carousel-controls-button-gap: 5px;
$carousel-controls-button-background-colour: rgba($_teal, 0.8);
$carousel-controls-button-border-radius: 0;
$carousel-controls-button-box-shadow: 0 transparent;
$carousel-controls-position-y: bottom;
$carousel-controls-position-x: split;
$carousel-controls-margin-y: 40px;
$carousel-controls-margin-x: 10px;
$carousel-controls-icon-colour: $white;
$carousel-controls-icon-font-size: 1.2em;
$carousel-controls-next-icon: "\f054"; // TODO: These are temporarily suspended
$carousel-controls-prev-icon: "\f053"; // TODO: These are temporarily suspended

// Carousel - Buttons
$carousel-buttons-enabled: false;

// Carousel - Video
$carousel-video-btn-position-y: 50%;
$carousel-video-btn-position-x: 50%;
$carousel-yt-background-enabled: true;
$carousel-yt-background-size: "contain";
$carousel-yt-cover-padding-bottom: 56.25%;

// Carousel - Scroll button
$carousel-scroll-btn-enabled: false;
$carousel-scroll-btn-size: $carousel-controls-button-size;
$carousel-scroll-btn-background-colour: transparent;
$carousel-scroll-btn-border-radius: 0;
$carousel-scroll-btn-box-shadow: none;
$carousel-scroll-btn-icon-colour: $body-colour;
$carousel-scroll-btn-icon-font-size: 1.4em;
$carousel-scroll-btn-icon: '\f078';
$carousel-scroll-btn-margin-y: -7.5%;
$carousel-scroll-btn-animation-prefab: 1;


// Carousel - Mobile
$carousel-mobile-details-background-colour: $_teal;
$carousel-mobile-heading-colour: $white;
$carousel-mobile-summary-colour: $white;

// Carousel - Banner min height
$banner-min-height: 350px;

// Home intro
$home-intro-max-width: 100%;
$home-intro-background-colour: $grey-lightest;
$home-intro-content-max-width: $container-max-width;
$home-intro-content-align: center;
$home-intro-padding-y: $spacer * 3;
$home-intro-margin-top: 0;
$home-intro-margin-bottom: $spacer;
$home-intro-text-align: center;
$home-intro-text-transform: $headings-text-transform;
$home-intro-font-family: $headings-font-family;
$home-intro-font-size: $font-size-h2;
$home-intro-font-weight: $headings-font-weight;
$home-intro-line-height: $line-height-base;
$home-intro-colour: $body-colour;
$home-intro-border-radius: 0;


// Home features
$home-features-max-width: $container-max-width;
$home-features-background-colour: transparent;
$home-features-padding-top: $spacer;
$home-features-padding-bottom: $spacer * 5;
$home-features-cards-entry-animation-prefab: 0;
$home-features-cards-entry-animation-stagger: true;
$home-features-cards-entry-animation-stagger-count: 3;


// Impact stats
$impact-stats-enabled: true;
$impact-stats-max-width: 100%;
$impact-stats-margin-top: 0;
$impact-stats-margin-bottom: 0;
$impact-stats-padding-y: $spacer;
$impact-stats-background-colour: darken($_teal, 5%);
$impact-stats-background-image: "perry-grone-lbLgFFlADrY.jpg";
$impact-stats-background-image-opacity: 0.2;
$impact-stats-background-image-size: cover;
$impact-stats-background-image-repeat: no-repeat;
$impact-stats-border-radius: 0;
$impact-stats-colour: $white;
$impact-stats-text-align: center;
$impact-stats-content-max-width: $container-max-width;
$impact-stats-breakpoint: map-get($breakpoints, md);
$impact-stats-overall-container-direction: column;
$impact-stats-direction: row;
$impact-stats-mobile-text-align: $impact-stats-text-align;

// Heading
$impact-stats-heading-enabled: true;
$impact-stats-heading-font-size: $font-size-h1;
$impact-stats-heading-text-align: center;
$impact-stats-heading-colour: inherit;
$impact-stats-heading-margin-bottom: $spacer;

// Individual stat
$impact-stat-min-width: 180px;
$impact-stat-direction: column;
$impact-stat-padding: $spacer 0;
$impact-stat-margin: $gap-width;

// Figure
$impact-stats-figure-colour: $impact-stats-colour;
$impact-stats-figure-font-family: $headings-font-family;
$impact-stats-figure-font-size: 4rem;

// Summary
$impact-stats-summary-colour: $white;
$impact-stats-summary-font-size: $font-size-large;
$impact-stats-summary-font-weight: $font-weight-base;
$impact-stats-summary-margin-top: .75rem;

// Home feeds
$home-feeds-max-width: $container-max-width;
$home-feeds-background-colour: transparent;
$home-feeds-padding-top: $spacer * 4;
$home-feeds-padding-bottom: $spacer * 4;
$home-feeds-cards-entry-animation-prefab: 0;
$home-feeds-cards-entry-animation-stagger: true;
$home-feeds-cards-entry-animation-stagger-count: 3;

// Feeds title
$feeds-title-font-family: $headings-font-family;
$feeds-title-font-size: $font-size-h2;
$feeds-title-colour: text-contrast($home-feeds-background-colour);
$feeds-title-text-align: center;

// Feed item
$feed-item-read-more-enabled: false;

// Event feed items
$feed-item-event-start-date-enabled: true;
$feed-item-event-location-enabled: false;

// Twitter feed
$home-feed-twitter-enabled: false;

// Footer
$footer-boxes-count: 3;
$footer-prefab: 7;
$footer-max-width: 100%;
$footer-contents-max-width: $container-max-width;
$footer-margin-y: 0;
$footer-padding-y: $spacer * 3;
$footer-background-colour: $_teal;
$footer-colour: $white;
$footer-link-colour: rgba($white, 0.65);
$footer-text-align: left;
$footer-headings-font-size: $font-size-h4;

// Footer admin links
$footer-admin-basket-link-enabled: true;
$footer-admin-login-link-enabled: true;
$footer-admin-manager-link-enabled: true;
$footer-admin-my-details-link-enabled: true;
$footer-admin-logout-link-enabled: true;

// Newsletter
$newsletter-background-colour: transparent;
$newsletter-colour: $white;
$newsletter-heading-font-family: $headings-font-family;
$newsletter-heading-font-size: $footer-headings-font-size;
$newsletter-heading-text-align: left;
$newsletter-heading-text-transform: $headings-text-transform;
$newsletter-heading-margin-bottom: $spacer * 2;
$newsletter-heading-colour: $white;
$newsletter-input-background-colour: $white;
$newsletter-button-height: 38px; // Hard code this so you can match it with the inputs
$newsletter-button-background-colour: $_green;
$newsletter-button-colour: $white;
$newsletter-button-border-colour: transparent;
$newsletter-use-placeholders: false;

// Context container
$context-container-background-colour: $grey-lightest;
$context-container-padding: $spacer * 2;
$context-container-border-radius: 0;

// Donors list
$donors-list-enabled: false;
$donors-list-profile-pics-enabled: false;

// Posts
$post-content-max-width: 1100px;

// Header text
$header-text-margin-top: $spacer * 3;
$header-text-margin-bottom: $spacer * 1.5;

// Page title
$page-title-font-size: $font-size-h1;
$page-title-font-family: $headings-font-family;
$page-title-font-weight: $headings-font-weight;
$page-title-colour: $_teal;
$page-title-text-transform: $headings-text-transform;
$page-title-text-align: left;
$page-title-margin-top: 0;
$page-title-margin-bottom: $headings-margin-bottom;

// Sidebar
$sidebar-enabled: false;
$sidebar-layout: adjacent;
$sidebar-mobile-layout: below;
$sidebar-adjacent-width: 300px;
$sidebar-adjacent-gap: $spacer;
$sidebar-background-colour: transparent;
$sidebar-padding: 0;

// Associated lists
$associated-latest-enabled: false;
$associated-related-enabled: false;
$associated-most-read-enabled: false;
$associated-tag-cloud-enabled: false;
$associated-max-number-of-posts: 3;
$associated-list-heading-font-size: $font-size-h4;
$associated-item-image-enabled: false;
$associated-item-heading-font-size: $font-size-base;
$associated-item-summary-enabled: true;
$associated-item-border: true;

// Listing
$listing-content-max-width: $container-max-width;
$listing-intro-max-width: $post-content-max-width;
$listing-cards-per-row-desktop: 3;
$listing-cards-per-row-tablet: 2;
$listing-card-min-width: $card-min-width;

// Listed post settings
$listed-post-read-more-enabled: false;
$listed-news-post-publish-date-enabled: false;
$listed-blog-post-publish-date-enabled: false;
$listed-blog-post-author-enabled: false;
$listed-event-post-start-date-enabled: true;
$listed-event-post-location-enabled: false;
$listed-appeal-totaliser-enabled: false;

// Breadcrumb
$breadcrumb-enabled: true;
$breadcrumb-margin-top: $spacer;
$breadcrumb-margin-bottom: $spacer;
$breadcrumb-max-width: $post-content-max-width;
$breadcrumb-text-align: $page-title-text-align;

// Blockquote
$blockquote-background-colour: $_teal;
$blockquote-border-radius: 0;
$blockquote-margin-y: $spacer * 3;
$blockquote-padding: $spacer * 2 $spacer * 3;
$blockquote-text-align: center;
$blockquote-font-size: 1.4em; // Em unit will cascade to children
$blockquote-font-family: $headings-font-family;
$blockquote-font-weight: $headings-font-weight;
$blockquote-line-height: $line-height-base;
$blockquote-colour: $white;
$blockquote-box-shadow: none;

// Fundraising
$fundraising-enabled: true;

// Find a fundraiser widget
$find-a-fundraiser-widget-enabled: true;
$find-a-fundraiser-profile-pics-enabled: $profile-pics-enabled;

// Top fundraisers
$top-fundraisers-enabled: true;
$top-fundraisers-profile-pics-enabled: $profile-pics-enabled;

// Donation form
$donation-form-layout: columns;
$donation-form-banner-enabled: true;
$donation-form-frequency-tabs: true;

// Donation amounts
$donation-amount-min-width: $card-min-width;
$donation-amount-figure-font-size: $font-size-h2;
$donation-amount-figure-colour: $_teal;
$donation-amount-thumbnail-enabled: true;

// Quick giving panel
$quick-giving-type: simple;
$quick-giving-background-colour: $context-container-background-colour;
$quick-giving-padding: $context-container-padding;
$quick-giving-border-radius: 0;
$quick-giving-donation-amount-figure-font-size: $donation-amount-figure-font-size*0.75;
$quick-giving-donation-amount-padding: 0.5rem 1rem;
$quick-giving-donation-amount-background-colour: $_purple;
$quick-giving-donation-amount-figure-colour: $white;
$quick-giving-donation-amount-border-width: 2px;
$quick-giving-donation-amount-border-colour: $_purple;
$quick-giving-donation-amount-selected-background-colour: $white;
$quick-giving-donation-amount-selected-figure-colour: $_teal;

// Home quick giving panel
$home-quick-giving-enabled: true;
$home-quick-giving-type: simple;
$home-quick-giving-max-width: 100%;
$home-quick-giving-content-max-width: 100%;
$home-quick-giving-margin-top: 0;
$home-quick-giving-margin-bottom: $spacer;
$home-quick-giving-padding-y: $spacer * 3.5;
$home-quick-giving-background-colour: darken($_light-blue, 10%);
$home-quick-giving-donation-amount-background-colour: $white;
$home-quick-giving-donation-amount-figure-colour: $_teal;
$home-quick-giving-donation-amount-border-width: 2px;
$home-quick-giving-donation-amount-border-colour: $_teal;
$home-quick-giving-donation-amount-selected-background-colour: $_teal;
$home-quick-giving-donation-amount-selected-figure-colour: $white;
$home-quick-giving-donate-btn-background-colour: $_purple;
$home-quick-giving-donate-btn-colour: $btn-text-colour;
$home-quick-giving-colour: $body-colour;
$home-quick-giving-heading-colour: $white;
$home-quick-giving-heading-font-size: $font-size-h1;

// Totalisers
$totaliser-enabled: true;
$totaliser-layout: horizontal;
$totaliser-width: 60px;
$totaliser-panel-background-colour: transparent;

// Comments
$comment-add-image-enabled: true;
$comment-add-video-enabled: true;

// Blog details
$blog-details-enabled: true;
$blog-details-background-colour: $context-container-background-colour;
$blog-details-padding: $context-container-padding;
$blog-details-border-radius: 0;
$blog-details-profile-pic-enabled: $profile-pics-enabled;

// FAQs
$faq-content-max-width: $post-content-max-width;
$faq-item-header-background-colour: $grey-lightest;
$faq-item-header-padding: $context-container-padding;
$faq-item-header-border-radius: 4px;
$faq-item-header-font-size: $font-size-h3;
$faq-item-btn-background-colour: $_teal;
$faq-item-btn-chevron-colour: $grey-lightest;
$faq-item-hover-btn-background-colour: $_teal;
$faq-item-hover-btn-chevron-colour: darken($grey-lightest, 2.5%);

// Shop
$shop-enabled: true;
$basket-link-only-on-shop-pages: false;

// Departments list
$department-list-width: 250px;
$department-list-background-colour: $context-container-background-colour;
$department-list-padding: $context-container-padding;
$department-list-border-radius: 2px;

// Product-price
$product-price-font-size: $font-size-h4;

// Product post
$product-post-content-max-width: $post-content-max-width;

// Cookie consent
$cookie-consent-position: bottom;
$cookie-consent-background-colour: rgba($black, 0.85);
$cookie-consent-colour: $white;
$cookie-consent-btn-background-colour: $_teal;
$cookie-consent-btn-text-colour: $white;
