// Home features
.homeFeatures {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 13
  )
}

// HomeBoxes
.homeFeatures .homeFeature {
  @include card;
  @include card-text-overlay;
}

// Home feeds
@include home-feeds-prefab (
  $number-of-feeds: 2,
  $prefab: 1
);

// News feed
.homeFeed[class*="news"] .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 14
  );
  .feedItem {
    @include card (
      $card-hover-heading-colour: $white,
      $card-heading-colour: $white,
      $card-summary-colour: $white,
      $card-hover-summary-colour: $white,
      $card-image-background-colour: darken($_teal, 10%),
      $card-box-shadow: none,
      $card-hover-box-shadow: none,
      $card-details-background-colour: rgba($_teal, 0.8),
      $card-hover-details-background-colour: rgba($_teal, 0.9)
    );
    @include card-text-overlay;
  }
}

// Event feed
.homeFeed[class*="event"] .feedList {
  @include cards-layout-prefab (
      $number-of-cards: 3,
      $prefab: 13
  );
  .feedItem {
    @include card (
      $card-hover-heading-colour: $white,
      $card-heading-colour: $white,
      $card-summary-colour: $white,
      $card-hover-summary-colour: $white,
      $card-image-background-colour: darken($_teal, 10%),
      $card-box-shadow: none,
      $card-hover-box-shadow: none,
      $card-details-background-colour: rgba($_teal, 0.8),
      $card-hover-details-background-colour: rgba($_teal, 0.9)
  );
    @include card-text-overlay;
  }
}

// Listed posts

body:not([class*="sbs"]) {
  .listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem) {
    @include card (
      $card-hover-heading-colour: $white,
      $card-heading-colour: $white,
      $card-summary-colour: $white,
      $card-hover-summary-colour: $white,
      $card-image-background-colour: darken($_teal, 10%),
      $card-box-shadow: none,
      $card-hover-box-shadow: none,
      $card-details-background-colour: rgba($_teal, 0.8),
      $card-hover-details-background-colour: rgba($_teal, 0.9)
  );
    @include card-text-overlay;
  }
}

// Alternative listing page option
body[class*="sbs"] {
  .listedPost {
    width: 100%;
    @include card;
    @include card-side-by-side;

    &:nth-of-type(odd) {
      @include card (
        $card-details-padding: 2rem,
        $card-hover-image-scale: 1,
        $card-text-align: left,
        $card-hover-heading-colour: $white,
        $card-heading-colour: $white,
        $card-hover-summary-colour: $white,
        $card-summary-colour: $white,
        $card-image-background-colour: transparent,
        $card-box-shadow: none,
        $card-hover-box-shadow: none,
        $card-details-background-colour: darken($_purple, 2.5%),
        $card-hover-details-background-colour: darken($_purple, 5%),
        $card-gap-width: 0
      );
      @include card-side-by-side (
        $card-side-by-side-image-position: left
      );
    };

    &:nth-of-type(even) {
      @include card (
        $card-details-padding: 2rem,
        $card-hover-image-scale: 1,
        $card-text-align: right,
        $card-hover-heading-colour: $white,
        $card-heading-colour: $white,
        $card-hover-summary-colour: $white,
        $card-summary-colour: $white,
        $card-image-background-colour: transparent,
        $card-box-shadow: none,
        $card-hover-box-shadow: none,
        $card-details-background-colour: darken($_teal, 2.5%),
        $card-hover-details-background-colour: darken($_teal, 5%),
        $card-gap-width: 0
      );
      @include card-side-by-side (
        $card-side-by-side-image-position: right
      );
    };


    &:nth-of-type(5n):nth-of-type(odd) {
      @include card (
        $card-details-padding: 2rem,
        $card-hover-image-scale: 1,
        $card-text-align: left,
        $card-hover-heading-colour: $white,
        $card-heading-colour: $white,
        $card-hover-summary-colour: $white,
        $card-summary-colour: $white,
        $card-image-background-colour: transparent,
        $card-box-shadow: none,
        $card-hover-box-shadow: none,
        $card-gap-width: 0,
        $card-details-background-colour: darken($_green, 2.5%),
        $card-hover-details-background-colour: darken($_green, 5%),
        );
      @include card-side-by-side (
        $card-side-by-side-image-position: left
      );
    };

    &:nth-of-type(5n):nth-of-type(even) {
      @include card (
        $card-details-padding: 2rem,
        $card-hover-image-scale: 1,
        $card-text-align: right,
        $card-hover-heading-colour: $white,
        $card-heading-colour: $white,
        $card-hover-summary-colour: $white,
        $card-summary-colour: $white,
        $card-image-background-colour: transparent,
        $card-box-shadow: none,
        $card-hover-box-shadow: none,
        $card-gap-width: 0,
        $card-details-background-colour: darken($_green, 2.5%),
        $card-hover-details-background-colour: darken($_green, 5%),
      );
      @include card-side-by-side (
        $card-side-by-side-image-position: right
      );
    }

    ;

    // &:nth-of-type(3n) {
    //   @include card (
    //     $card-details-padding: 2rem,
    //     $card-hover-image-scale: 1,
    //     $card-text-align: left,
    //     $card-hover-heading-colour: $white,
    //     $card-heading-colour: $white,
    //     $card-hover-summary-colour: $white,
    //     $card-summary-colour: $white,
    //     $card-image-background-colour: transparent,
    //     $card-box-shadow: none,
    //     $card-hover-box-shadow: none,
    //     $card-details-background-colour: darken($_purple, 2.5%),
    //     $card-hover-details-background-colour: darken($_purple, 5%),
    //     $card-gap-width: 0
    //   );
    //   @include card-side-by-side (
    //     $card-side-by-side-image-position: left
    //   );
    // }

    // &:nth-of-type(even) {
    //   @include card-side-by-side (
    //     $card-side-by-side-image-position: right
    //   );
    // }
  }
}


// Listed productst
.listedProduct {
  @include card (
    $card-summary-enabled: true,
    $card-hover-heading-colour: $white,
    $card-heading-colour: $white,
    $card-summary-colour: $white,
    $card-hover-summary-colour: $white,
    $card-image-background-colour: darken($_teal, 10%),
    $card-box-shadow: none,
    $card-hover-box-shadow: none,
    $card-details-background-colour: rgba($_teal, 0.8),
    $card-hover-details-background-colour: rgba($_teal, 0.9)
  );
  @include card-text-overlay;
}
